import React from "react"
import Layout from "../components/layout"

export default function Friluftsliv() {
  return (
    <Layout>
      <div className="text-yellow-500">Basecamp er kuult</div>
    </Layout>
  )
}
